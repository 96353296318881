import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [~4],
		"/(auth)/admin": [5,[2]],
		"/(auth)/admin/logs": [~6,[2]],
		"/(auth)/admin/stats": [~7,[2]],
		"/(auth)/campaigns": [~18],
		"/(auth)/campaign/[id]": [~8,[3]],
		"/(auth)/campaign/[id]/admin": [~10,[3]],
		"/(auth)/campaign/[id]/beloningen": [~11,[3]],
		"/(auth)/campaign/[id]/community": [~12,[3]],
		"/(auth)/campaign/[id]/community/terugbetalingen": [~13,[3]],
		"/(auth)/campaign/[id]/doel": [~14,[3]],
		"/(auth)/campaign/[id]/gegevens": [~15,[3]],
		"/(auth)/campaign/[id]/support": [~16,[3]],
		"/(auth)/campaign/[id]/verhaal": [~17,[3]],
		"/(auth)/campaign/[id]/[...fallback]": [9,[3]],
		"/(auth)/design": [19],
		"/login": [~20],
		"/logout": [~21],
		"/passwordreset": [~22],
		"/terugbetaalschema": [23]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';